
import { Options, Vue } from 'vue-class-component';
import { Prop, Model, Watch } from 'vue-property-decorator';

@Options({ emits: ['currentTimeApplied', 'update:currentTime'] })
export default class VideoSlider extends Vue {
  @Prop({ type: Number, default: 0 }) readonly duration!: number;
  @Model('currentTimeChange', { type: Number, default: 0 })
  readonly currentTime!: number;

  @Watch('currentTime') currentTimeChange(): void {
    // If the user is seeking, do nothing
    if (this.isSeeking) return;
    this.value = this.currentTime;
  }

  private value = 0;
  private isSeeking = false;

  mounted(): void {
    this.value = +this.currentTime;
  }

  convertToTimeFormat(seconds: number): string {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  currentTimeChanged(): void {
    this.$emit('update:currentTime', +this.value);
  }

  currentTimeApplied(): void {
    this.$emit('currentTimeApplied', +this.value);
  }
}
