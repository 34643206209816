
import { Options, Vue } from 'vue-class-component';
import socketClient from '@/services/socket-client.service';
import {
  SocketMsg,
  SocketRemoteActionMsgPayload,
  CONTROLLER_ACTION,
  SocketMediaPlaybackMsgPayload,
} from '../../../shared/models/socket-events';
import { filter } from 'rxjs/operators';
import {
  PlaybackInfo,
  SUBTITLE_OFFSET_ACTION,
  SUBTITLE_SIZE_ACTION,
  VOLUME_ACTION,
} from '../../../shared/models/common-models';
import VideoSlider from '@/components/VideoSlider.vue';
import OverlayedPopup from '@/components/OverlayedPopup.vue';
import { Subscription } from 'rxjs';
import { event } from 'vue-gtag';

// The number of seconds to skip when clicking forward or back
const SKIP_TIME = +(process.env.VUE_APP_SKIP_TIME as string);

declare type REMOTE_STATE =
  | 'connecting'
  | 'reconnecting'
  | 'disconnected'
  | 'none';

@Options({ components: { VideoSlider, OverlayedPopup } })
export default class Remote extends Vue {
  isPlayingVideo = false;
  remoteState: REMOTE_STATE = 'connecting';
  sessionId!: string;
  playbackInfo: PlaybackInfo = {
    videoName: '',
    currentTime: 0,
    duration: 0,
  };

  private subscription = new Subscription();

  sendRemoteMessage(
    actionName: CONTROLLER_ACTION,
    actionValue?: string | number
  ): void {
    const payload: SocketRemoteActionMsgPayload = {
      actionName,
    };

    if (actionValue) payload.actionValue = actionValue;

    const msg: SocketMsg<SocketRemoteActionMsgPayload> = {
      type: 'remote-action',
      payload,
    };

    // Log out all remote actions events on the server
    event(actionName, {
      event_category: 'remote-action',
      event_label: actionValue,
    });

    socketClient.sendMessage(msg);
  }

  onPlayPauseClick(): void {
    this.isPlayingVideo = !this.isPlayingVideo;
    this.sendRemoteMessage('play-pause');
  }

  onBackwardClick(): void {
    this.sendRemoteMessage('backward', SKIP_TIME);
  }

  onForwardClick(): void {
    this.sendRemoteMessage('forward', SKIP_TIME);
  }

  onResetSubtitles(): void {
    this.sendRemoteMessage('subtitles-reset');
  }

  onVideoApplyCurrentTime(value: number): void {
    this.sendRemoteMessage('change-currenttime', value);
  }

  onChangeSubtitlesOffsetClick(action: SUBTITLE_OFFSET_ACTION): void {
    this.sendRemoteMessage('subtitles-offset', action);
  }

  onChangeSubtitlesSizeClick(action: SUBTITLE_SIZE_ACTION): void {
    this.sendRemoteMessage('subtitles-size', action);
  }

  onToggleFullscreenClick(): void {
    this.sendRemoteMessage('toggle-fullscreen');
  }

  onVolumeClick(action: VOLUME_ACTION): void {
    this.sendRemoteMessage('change-volume', action);
  }

  created(): void {
    this.sessionId = this.$route.meta['sessionId'] as string;
    this.playbackInfo = this.$route.meta['playbackInfo'] as PlaybackInfo;
    this.isPlayingVideo = this.playbackInfo?.isPlaying || false;
  }

  async mounted(): Promise<void> {
    // If we got no playback information, it means this session does not exist, set state to disconnected and exit
    if (this.playbackInfo === undefined) {
      this.remoteState = 'disconnected';
      return;
    }

    try {
      await socketClient.connect(this.sessionId, 'remote');
      this.remoteState = 'none';

      this.handleSocketMessages();
      this.handleSocketConnections();
    } catch (error) {
      console.error(error);
      this.remoteState = 'disconnected';
    }
  }

  private handleSocketMessages(): void {
    this.subscription.add(
      socketClient.message
        .pipe(
          filter((msg) => {
            return msg.type === 'media-playback';
          })
        )
        .subscribe((msg) => {
          const playbackInfo = msg.payload as SocketMediaPlaybackMsgPayload;

          // Merge with the existing playback info, as the object we get emit only what was changed
          this.playbackInfo = {
            ...this.playbackInfo,
            ...playbackInfo,
          };

          // If we got the playback status, update it
          if (playbackInfo.isPlaying !== undefined)
            this.isPlayingVideo = playbackInfo.isPlaying;
        })
    );
  }

  private handleSocketConnections(): void {
    this.subscription.add(
      socketClient.disconnected.subscribe((status) => {
        if (status.retrying) this.remoteState = 'reconnecting';
        else this.remoteState = 'disconnected';
      })
    );

    this.subscription.add(
      socketClient.connected.subscribe(() => (this.remoteState = 'none'))
    );
  }

  unmounted(): void {
    socketClient.disconnect();
    this.subscription.unsubscribe();
  }
}
