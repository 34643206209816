import { withModifiers as _withModifiers, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-slider w-100 d-flex flex-column" }
const _hoisted_2 = ["max"]
const _hoisted_3 = { class: "slider-info d-flex w-100 justify-content-between pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isSeeking = true), ["left"])),
      onMouseup: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isSeeking = false), ["left"])),
      onPointerdown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSeeking = true)),
      onPointerup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSeeking = false)),
      onPointerleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSeeking = false)),
      onPointerout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isSeeking = false)),
      ref: "slider",
      class: "flex-fill",
      type: "range",
      min: 0,
      max: _ctx.duration,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.currentTimeChanged && _ctx.currentTimeChanged(...args))),
      onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.currentTimeApplied && _ctx.currentTimeApplied(...args)))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, _toDisplayString(_ctx.convertToTimeFormat(_ctx.value)), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.convertToTimeFormat(_ctx.duration)), 1 /* TEXT */)
    ])
  ]))
}